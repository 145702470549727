/**
 * For 508 compliance, this page needs to render without javascript ;_;
 * The functionality here is non-essential, simply faciliating checkbox selection
 */

function parentOf($el) {
  const parentId = $el.data('parent-id');
  const parentType = $el.data('parent');
  if (!parentId || !parentType) { return false; }
  return $(`select[data-id="${parentId}"][data-type="${parentType}"]`);
}

function childrenOf($el) {
  const id = $el.data('id');
  const type = $el.data('type');
  return $(`select[data-parent-id="${id}"][data-parent="${type}"]`);
}

// gets the span that holds the icon for the select operation
function spanForSelect($select) {
  const id = $select.data('id');
  return $(`#icon-for-${id}`);
}

function styleDivForSelect($select) {
  const id = $select.data('id');
  return $(`#style-div-for-${id}`);
}

function matchSync($el, $other) {
  if ($el.is(':checked')) {
    $other.attr('checked', $el.attr('checked'));
  } else {
    $other.removeAttr('checked');
  }
}

function cascadeSyncDown($el) {
  childrenOf($el, 'input').each((_index, child) => {
    const $child = $(child);
    matchSync($el, $child);
    cascadeSyncDown($child);
  });
}

function cascadeUnsyncUp($el) {
  const $parent = parentOf($el, 'input');
  if (!$parent) { return; }
  matchSync($el, $parent);
  cascadeUnsyncUp($parent);
}

// Yea using 1 and 0 for t/f is dumb but checkboxes...and consistency ;_;
function onChecked(event) {
  const $target = $(event.currentTarget);
  if ($target.is(':checked')) {
    cascadeSyncDown($target);
  } else {
    cascadeUnsyncUp($target);
    cascadeSyncDown($target);
  }
}

function onSelected(event) {
  const $target = $(event.currentTarget);
  const selected = $target.children(':selected').val();
  changeClassForSelection($target, selected);
  cascadeUnselectUp($target, selected);
  cascadeSelectDown($target, selected);
  showHideIntervalSelection($target);
  showHidePermissionsSelection($target);
}

function cascadeSelectDown($el, selected) {
  childrenOf($el, 'select').each((_index, child) => {
    if (child.name.includes('[sync_type]')) {
      const $child = $(child);
      $child.val(selected);
      changeClassForSelection($child, selected);
      cascadeSelectDown($child, selected);
      showHideIntervalSelection($child);
      showHidePermissionsSelection($child);
    }
  });
}

function cascadeUnselectUp($el, selected) {
  const $parent = parentOf($el, 'select');
  if (!$parent) { return; }
  $parent.val('ignored');
  changeClassForSelection($parent, 'ignored');
  cascadeUnselectUp($parent, selected);
}

function changeClassForSelection($selectTarget, selected) {
  const $icon = spanForSelect($selectTarget);
  const $styleDiv = styleDivForSelect($selectTarget);
  $styleDiv.attr('title', titleTextForOptionValue(selected));
  _.forEach($selectTarget.children(), (child) => {
    $icon.removeClass(iconClassForValue(child.value));
  });
  $icon.addClass(iconClassForValue(selected));
}

function showHideIntervalSelection($target) {
  if ($target[0].dataset.type === 'layer') {
    const id = $target[0].id.replace('_sync_type', '_sync_interval_hours');
    const $elem = $(document.getElementById(id));
    if ($target.val() === 'data') {
      $elem.attr('hidden', false);
    } else {
      $elem.attr('hidden', true);
    }
  }
}

function showHidePermissionsSelection($target) {
  if ($target[0].dataset.type === 'layer') {
    const id = $target[0].id.replace('_sync_type', '_permissions_scope');
    const $elem = $(document.getElementById(id));
    if ($target.val() !== 'ignored') {
      $elem.attr('hidden', false);
    } else {
      $elem.attr('hidden', true);
    }
  }
}

function titleTextForOptionValue(selectedValue) {
  switch (selectedValue) {
    case 'ignored':
      return $.t('screens.admin.connector.ignored_option');
    case 'data':
      return $.t('screens.admin.connector.data_option');
    case 'catalog':
      return $.t('screens.admin.connector.catalog_option');
    default:
      console.warn(`Unexpected value selection: ${selectedValue}, no title text found`);
      return '';
  }
}

function iconClassForValue(selectedValue) {
  switch (selectedValue) {
    case 'ignored':
      return 'icon-plus3';
    case 'data':
      return 'icon-data';
    case 'catalog':
      return 'icon-external-square';
    default:
      console.warn(`Unexpected value selection: ${selectedValue}, no icon found`);
      return '';
  }
}

function setConnectionStrategy() {
  return (event) => {
    const $target = $(event.currentTarget);
    const selectAllAssets = ($target.val() === 'all' || $target.val() === 'catalog' || $target.val() === 'data');
    const setState = ($el) => ($el.attr('disabled', selectAllAssets));
    const setDisabledClass = ($el) => ($el[selectAllAssets ? 'addClass' : 'removeClass']('is-disabled'));

    // Esri use case
    setDisabledClass($('.select-style'));
    setDisabledClass($('.select-icon'));
    setState($('select.sync-type'));

    // Catalog Federator use case
    setState($('fieldset.line input[type=checkbox]'));
    setState($('#select_all_button'));
    setState($('#clear_all_button'));
  };
}

function showFocusOnSelect(event) {
  const $target = $(event.currentTarget);
  const $styleDiv = $target.parent();
  $styleDiv.addClass('has-focus');
}

function removeFocusOnSelect(event) {
  const $target = $(event.currentTarget);
  const $styleDiv = $target.parent();
  $styleDiv.removeClass('has-focus');
}

function disableSave(event) {
  const $target = $(event.currentTarget);
  _.defer(() => {
    $target.siblings('.save-connector-spinner').show();
    $target.addClass('disabled').attr('disabled', true);
  });
}

$(() => {
  const filterElements = (event) => {
    const term = $(event.currentTarget).val().toLowerCase();
    const cTerm = term.toLowerCase();

    const hasTerm = (el) => {
      return _.get(el.querySelector('.asset-name'), 'innerText', '')
        .toLowerCase()
        .indexOf(cTerm) !== -1;
    };

    const showOrHide = (el) => {
      const $el = $(el);

      if (hasTerm(el)) {
        $el.show();
        $el.parent().show();
        return true;
      } else {
        $el.hide();
        return false;
      }
    };

    const cssSelector = $('.esri-tree').length > 0 ?
      '.parent, .child.layer.search-item' :
      '.search-item';

    $(cssSelector).each(function() {
      showOrHide(this);
    });

    //if all children have been hidden, hide the parent to reduce whitespace
    $('.child.service.search-item').each(function() {
      if ($(this).children().filter(':visible').length == 0) {
        $(this).hide();
      }
    });
  };
  $('.filter-assets .searchBox').on('keyup', _.debounce(filterElements, 300));

  $('.sync-type-select').on('focusin', showFocusOnSelect);
  $('.sync-type-select').on('focusout', removeFocusOnSelect);

  $('.sync-type-check').on('change', onChecked);
  $('.sync-type-select').on('change', onSelected);
  $('.server-sync').on('change', setConnectionStrategy());

  $('input:submit').on('click', disableSave);
});
